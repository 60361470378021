<template src="./ClosureRequests.html"></template>

<script>
import idiService from "../../services/Idi";
import ArrobaMedellinArrobitaNoData from "../../components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import { mapGetters } from "vuex";

export default {
  name: "closureRequests",
  components: {
    ArrobaMedellinArrobitaNoData,
    ChooseUniversity,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Solicitudes de grupos de investigación",
    },
    notFound: {
      type: String,
      default: "No hay solicitudes en éste momento",
    },
  },
  data() {
    return {
      requests: [],
      modalRejectClose: false,
      modalCloseGroup: false,
      idGroup: null,
      groupRequest: null,
      institutionId: "",
      role: "",
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.getInfoRequest();
  },
  methods: {
    async getInfoRequest() {
      try {
        const infoRequest = {
          typeRequest: "delete",
          institutionId: this.institutionId,
        };
        this.requests = await idiService.groupRequestList(infoRequest);
      } catch (error) {
        console.error(error);
      }
    },
    showModalRejectClose(id) {
      this.modalRejectClose = true;
      this.idGroup = id;
    },
    showModalCloseGroup(id) {
      this.modalCloseGroup = true;
      this.idGroup = id;
    },
    async rejectRequest() {
      try {
        const tokenPhp = this.$store.getters.getTokenControllerPhp.toString();
        const infoReject = {
          statusRequest: "rejected",
          groupChangeRequestId: Number(this.idGroup),
          token: tokenPhp,
        };
        await idiService.agreeOrRejectGroup(infoReject);
        this.getInfoRequest();
        this.modalRejectClose = false;
      } catch (error) {
        console.error(error);
      }
    },
    async closeResearchGroup() {
      try {
        const groupId = Number(this.idGroup);
        await idiService.deleteGroup(groupId);
        this.getInfoRequest();
        this.modalCloseGroup = false;
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se cerro el grupo con éxito.",
          type: "is-success",
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getInfoRequest();
    },
  },
};
</script>

<style lang="scss" scoped src="./ClosureRequests.scss"></style>